<script>
  export default {
    props: {
      endpoint: {
        type: String,
        default: '/',
      },
      method: {
        type: String,
        default: 'POST',
      },
      data: {
        type: Object,
        default: () => {
          return {};
        }
      },
      dataLayer: {
        type: Object,
        default: () => {
          return {};
        }
      },
    },
    data() {
      return {
        processedData: {},
        dataLayerDefaults: {
          'event': 'gaEvent',
          'category': 'Form',
          'action': 'Submit',
        },
        resetData: {},
        show: true,
        loading: false,
        errors: false,
        success: false,
      }
    },
    created: function () {
      this.processedData = {...this.data};
      this.resetData = JSON.parse(JSON.stringify(this.data)); // Deep clone
    },
    methods: {
      submit($event) {
        $event.preventDefault();
        this.success = false;
        this.loading = true;

        const formData = new FormData();
        formData.append('CRAFT_CSRF_TOKEN', window.CSRF);
        formData.append('action', 'contact-form/send');
        formData.append('fromEmail', this.processedData.fromEmail);

        const params = Object.keys(this.processedData.message);
        params.forEach(param => {
          formData.append(`message[${param}]`,  this.processedData.message[param])
        })

        this.axios({
          method: 'POST',
          url: this.endpoint,
          data: formData,
          headers: {
            'Accept': 'application/json',
          },
        }).then(response => {
          this.handleResponse(response);
        }).finally(() => {
          this.loading = false;
        });
      },
      handleResponse(response) {
        if (response.data.errors !== undefined) {
          this.errors = response.data.errors;
        } else if (response.data.success !== undefined) {
          this.success = true;
          this.reset();
          this.pushToDataLayer();
        }
      },
      pushToDataLayer() {
        if (window.dataLayer !== undefined) {
          window.dataLayer.push({
            ...this.dataLayerDefaults,
            ...this.dataLayer,
          });
        }
      },
      reset() {
        this.processedData = JSON.parse(JSON.stringify(this.resetData)); // Deep clone
        this.errors = false;
      }
    },
    render() {
      return this.$slots.default({
        submit: this.submit,
        data: this.processedData,
        success: this.success,
        show: this.show,
        loading: this.loading,
        errors: this.errors,
      });
    }
  }
</script>

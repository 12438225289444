const backdrop = document.querySelector('#reservation .backdrop');
const close = document.querySelector('#reservation .close');

function openModal() {
    document.body.classList.add('reservation-modal-open');
}

function closeModal() {
    document.body.classList.remove('reservation-modal-open');
    window.location.hash = '';
}

window.addEventListener('DOMContentLoaded', function (event) {
    if (window.location.hash.substr(1) === 'reservation') {
        openModal();
    }
});

window.addEventListener('popstate', function (event) {
    if (window.location.hash.substr(1) === 'reservation') {
        openModal();
    }
});

if(backdrop) {
    backdrop.addEventListener("click", (event) => {
        closeModal();
    })
}

if(close) {
    close.addEventListener("click", (event) => {
        closeModal();
    })
}

import Swiper from 'swiper';

const timelineSlider = new Swiper(".swiper-testimonials", {
    effect: "slide",
    slidesPerView: 1,
    spaceBetween: 30,
    grabCursor: true,
    speed: 1500,
    autoplay: {
        delay: 8000,
    },
});

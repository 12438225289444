<script>
    import FormAbstract from './FormAbstract.vue';

    export default {
        mixins: [FormAbstract],
        data() {
            return {
                validationInline: false,
            }
        },
    }
</script>

import { createApp } from 'vue';

import axios from 'axios'
import VueAxios from 'vue-axios'

import FormAjax from './FormAjax';

const app = createApp({
    components: {
        FormAjax,
    },
});

app.use(VueAxios, axios);
app.mount('#form');

import {Loader} from '@googlemaps/js-api-loader';

let map = undefined;

function markPlace(results, status) {
    if (status == google.maps.places.PlacesServiceStatus.OK) {
        var marker = new google.maps.Marker({
            map: map,
            place: {
                placeId: results[0].place_id,
                location: results[0].geometry.location,
            },
        });
    }
}

function removeGoogleFont() {
    // Preventing the Google Maps libary from downloading an extra font
    const isGoogleFont = function (element) {

        // Google font download
        if (element.href
            && element.href.indexOf('https://fonts.googleapis.com/') === 0) {
            return true;
        }
        // Google style elements
        if (element.tagName.toLowerCase() === 'style'
            && element.styleSheet
            && element.styleSheet.cssText
            && element.styleSheet.cssText.replace('\r\n', '').indexOf('.gm-style') === 0) {
            element.styleSheet.cssText = '';
            return true;
        }
        // Google style elements for other browsers
        if (element.tagName.toLowerCase() === 'style'
            && element.innerHTML
            && element.innerHTML.replace('\r\n', '').indexOf('.gm-style') === 0) {
            element.innerHTML = '';
            return true;
        }
        // when google tries to add empty style
        if (element.tagName.toLowerCase() === 'style'
            && !element.styleSheet && !element.innerHTML) {
            return true;
        }

        return false;
    }

    // we override these methods only for one particular head element
    // default methods for other elements are not affected
    const head = document.getElementsByTagName('head')[0];

    const insertBefore = head.insertBefore;
    head.insertBefore = function (newElement, referenceElement) {
        if (!isGoogleFont(newElement)) {
            insertBefore.call(head, newElement, referenceElement);
        }
    };

    const appendChild = head.appendChild;
    head.appendChild = function (textNode) {
        if (!isGoogleFont(textNode)) {
            appendChild.call(head, textNode);
        }
    };
}

export default (apiKey) => {
    const loader = new Loader({
        apiKey: apiKey,
        version: "weekly",
        libraries: ["places"]
    });

    loader.load().then(() => {
        const latLng = new google.maps.LatLng({lat: 50.70627688666327, lng: 7.144848884914178});
        map = new google.maps.Map(document.getElementById("google-map"), {
            center: latLng,
            zoom: 17,
        });

        var request = {
            location: map.getCenter(),
            radius: '500',
            query: 'TAO Bonn'
        };

        var service = new google.maps.places.PlacesService(map);
        service.textSearch(request, markPlace);
    });

    removeGoogleFont();
}


import Alpine from 'alpinejs';
import AOS from 'aos';

import './modules/swiper-testimonials';
import './modules/reservation-modal';
import './modules/forms/index';

import GoogleMap from './modules/google-map';



window.addEventListener('load', function() {
    const embeds = document.querySelectorAll('.embed');
    const description = document.querySelector('.description');


    if(embeds) {
        embeds.forEach((embed) => {
            const placeholderImage = embed.querySelector('.embed-placeholder');
            const id = embed.getAttribute('id');
            const dataSrc = document.querySelector('.data-src_' + id)
            if(placeholderImage) {
                placeholderImage.addEventListener('click', (event) => {
                    const placeholderImageWrapper = event.target.parentNode;
                    const iframeWrapper = placeholderImageWrapper.nextSibling;
                    const iframe = iframeWrapper.nextSibling;

                    if (!iframeWrapper) {
                        return;
                    }

                    placeholderImageWrapper.classList.add('hide');
                    if(description) {
                        description.classList.add('hide');
                    }

                    if(iframe != null) {
                        const dataAttr = iframe.dataset.src;
                        iframe.setAttribute('src', dataAttr);
                    }
                    else {
                        const dataAttr = iframeWrapper.dataset.src;
                        iframeWrapper.setAttribute('src', dataAttr);
                    }
                })
            }
        });
    }
});

/**
 * Init custom modules
 */
window.GoogleMap = GoogleMap;

/**
 * Init Alpine
 *
 * If you imported Alpine into a bundle, you have to make sure you are registering any
 * extension code IN BETWEEN when you import the Alpine global object, and when you
 * initialize Alpine by calling Alpine.start().
 */
window.Alpine = Alpine;

Alpine.start();


AOS.init({
    offset: 75,
});

const loadedEvent = new Event('app-js-loaded');
document.dispatchEvent(loadedEvent)